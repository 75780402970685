<template>
  <div>
    <h2 v-html="$t('search_processing_row_title')"></h2>
    <v-row class="row search">
      <v-col xs="12" md="12" xl="6">
        <!-- mit Direktbezug -->
        <div class="flex-items">
          <v-text-field
            id="search_processing_row_direct_reference"
            :label="$t('search_processing_row_direct_reference')"
            type="string"
            @keyup.enter="
              () => {
                addReference('D')
                search
              }
            "
            v-model="directReferenceCode"
          />
          <span :id="'add-direct-reference'" @click="addReference('D')" class="add-reference">
            <v-icon dbmblueprimary>mdi-plus-circle-outline</v-icon>
          </span>
        </div>
      </v-col>
      <v-col xs="12" md="12" xl="6">
        <!--mit indirektem Bezug -->
        <div class="flex-items">
          <v-text-field
            id="search_processing_row_indirect_reference"
            :label="$t('search_processing_row_indirect_reference')"
            type="string"
            @keyup.enter="
              () => {
                addReference('I')
                search
              }
            "
            v-model="indirectReferenceCode"
          />
          <span :id="'add-indirect-reference'" @click="addReference('I')" class="add-reference">
            <v-icon dbmblueprimary>mdi-plus-circle-outline</v-icon>
          </span>
        </div>
      </v-col>
    </v-row>
    <v-data-table-server :headers="fields" :items="SearchProcessingRowStoreFullItems" :items-per-page-options="$itemsPerPageOptions" density="compact">
      <template v-slot:[`item.actions`]="{ item }">
        <span @click="deleteCode(item)" class="delete-reference">
          <v-icon dbmblueprimary>mdi-minus-circle-outline</v-icon>
        </span>
      </template>
    </v-data-table-server>
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import { emitter, apiURL } from '../../main'
import { showError } from '@/services/axios'
import _ from 'lodash'
import { DTSHeader } from '@/services/BackendService'
import { useSearchProcessingRowStore } from '@/store/SearchProcessingRowStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchProcessingRow',
  extends: baseSearchVue,
  data() {
    return {
      lodash: _,
      apiURL: apiURL + '/processingFormTemplateRows/maxiFormRows',
      directReferenceCode: '',
      indirectReferenceCode: '',
      processingFormTemplatePreview: [],
      counter: 0,
      fields: <DTSHeader[]>[
        // Löschen
        {
          title: this.$t('search_processing_row_delete'),
          key: 'actions'
        },
        // Bezug
        {
          title: this.$t('search_processing_row_reference'),
          key: 'reference',
          align: 'left'
        },
        // Code
        {
          title: this.$t('search_processing_row_code'),
          key: 'code'
        },
        // Formteil
        {
          title: this.$t('search_processing_row_form_part'),
          key: 'formPart'
        },
        // Gruppe
        {
          title: this.$t('search_processing_row_group'),
          key: 'group'
        },
        // Zusatz
        {
          title: this.$t('search_processing_row_addition'),
          key: 'addition'
        },
        // Produkt
        {
          title: this.$t('search_processing_row_product'),
          key: 'product'
        }
      ]
    }
  },
  computed: {
    SearchProcessingRowStoreFullItems() {
      return useSearchProcessingRowStore().fullItems
    }
  },
  methods: {
    reset() {
      useSearchProcessingRowStore().resetFullItems()
      useSearchProcessingRowStore().resetCodes()
      this.counter = 0
    },
    addReference(directness) {
      var referenceCode = ''
      if (directness === 'D') {
        referenceCode = this.directReferenceCode
      }
      if (directness === 'I') {
        referenceCode = this.indirectReferenceCode
      }
      if (this.validateCode(referenceCode) == false) return
      var splittedCode = referenceCode.split('.')
      var newReference = {
        id: 0,
        reference: directness,
        code: referenceCode,
        formPart: '',
        group: '',
        addition: '',
        product: ''
      }
      if (splittedCode.length > 0) {
        const rightProcessingRow = this.findProcessingRow(splittedCode)
        var rightGroup = {}
        var rightRowType = {}
        if (splittedCode.length > 1) {
          rightGroup = this.findGroup(rightProcessingRow, splittedCode)
        }
        if (splittedCode.length == 1) {
          newReference.formPart = rightProcessingRow[this.$getLangKey()]
        } else if (splittedCode.length == 2) {
          newReference.formPart = rightProcessingRow[this.$getLangKey()]
          newReference.group = rightGroup[this.$getLangKey()]
        } else if (splittedCode.length == 3) {
          rightRowType = this.findRowType(rightGroup, splittedCode)
          newReference.formPart = rightProcessingRow[this.$getLangKey()]
          newReference.group = rightGroup[this.$getLangKey()]
          newReference.addition = rightRowType['rowType' + this.$getUpLangKey()]
        } else {
          rightRowType = this.findRowType(rightGroup, splittedCode)
          const rightProduct = this.findProduct(rightGroup, splittedCode)
          newReference.formPart = rightProcessingRow[this.$getLangKey()]
          newReference.group = rightGroup[this.$getLangKey()]
          newReference.addition = rightRowType['rowType' + this.$getUpLangKey()]
          newReference.product = rightProduct['product' + this.$getUpLangKey()]
        }
        this.counter += 1
        newReference.id = this.counter
        useSearchProcessingRowStore().addItem(newReference)
        useSearchProcessingRowStore().addCode(this.itemsToCodesString())
      }
    },
    itemsToCodesString() {
      var finalCodesString = ''
      useSearchProcessingRowStore().fullItems.forEach((code) => {
        var codeString = ''
        if (finalCodesString.length > 1) {
          codeString = ',' + codeString
        }
        codeString = codeString + code.reference + ':' + code.code
        finalCodesString += codeString
      })
      return finalCodesString
    },
    findProcessingRow(splittedCode) {
      const rightProcessingRow = this.processingFormTemplatePreview.find((row) => row.ident == splittedCode[0])
      if (rightProcessingRow == undefined) {
        emitter.emit('toastError', this.$t('search_processing_row_row_not_found'))
      }
      return rightProcessingRow
    },
    findGroup(rightProcessingRow, splittedCode) {
      const rightGroup = rightProcessingRow.groups.find((group) => group.ident == splittedCode[1])
      if (rightGroup == undefined) {
        emitter.emit('toastError', {
          message: this.$t('search_processing_row_group_not_found')
        })
      }
      return rightGroup
    },
    findRowType(rightGroup, splittedCode) {
      const rightRowType = rightGroup.rows.find((row) => parseInt(row.rowTypeIdent) == parseInt(splittedCode[2]))
      if (rightRowType == undefined) {
        emitter.emit('toastError', {
          message: this.$t('search_processing_row_row_type_not_found')
        })
      }
      return rightRowType
    },
    findProduct(rightGroup, splittedCode) {
      const rightProduct = rightGroup.rows.find(
        (row) => parseInt(row.rowTypeIdent) == parseInt(splittedCode[2]) && parseInt(row.productCode) == parseInt(splittedCode[3])
      )
      if (rightProduct == undefined) {
        emitter.emit('toastError', {
          message: this.$t('search_processing_row_product_not_found')
        })
      }
      return rightProduct
    },
    async loadprocessingFormTemplatePreview() {
      try {
        const response = await this.axios.get(apiURL + '/processingFormTemplates/maxiForm', { headers: { Accept: 'application/json' } })
        const data = await response.data
        this.processingFormTemplatePreview = data
      } catch (e) {
        showError(e)
      }
    },
    validateCode(code) {
      let re =
        /^(10|20|30)$|^(10|20|30)\.([0-9][0-9]?[0-9]?)$|^(10|20|30)\.([0-9][0-9]?[0-9]?)\.([0-9][0-9]?[0-9]?)$|^(10|20|30)\.([0-9][0-9]?[0-9]?)\.([0-9][0-9]?[0-9]?)\.([0-9][0-9]?[0-9]?)$|^(10|20|30)\.([0-9][0-9]?[0-9]?)\.([0-9][0-9]?[0-9]?)\.([0-9][0-9]?[0-9]?)\.([0-9]?).{0,0}$/
      let result = re.test(code)
      //reset the fields
      this.directReferenceCode = ''
      this.indirectReferenceCode = ''
      if (!result) {
        emitter.emit('toastError', {
          message: this.$t('search_processing_row_code_not_valid')
        })
        return false
      }
      return true
    },
    async deleteCode(item) {
      useSearchProcessingRowStore().fullItems = _.remove(useSearchProcessingRowStore().fullItems, function (element) {
        return element.id != item.id
      })
      useSearchProcessingRowStore().codes = this.itemsToCodesString()
    }
  },
  mounted() {
    this.processingFormTemplatePreview = this.loadprocessingFormTemplatePreview()
    this.initSearchStoreFromQuery(this.SearchProcessingRowStore, this.$route.query)
  }
})
</script>

<style lang="scss" scoped>
.add-reference:hover {
  cursor: pointer;
}

.flex-items {
  display: flex;
  flex-flow: row;
}

/*Table*/
.v-data-table-server {
  margin-top: 0 !important;
}

.delete-reference:hover {
  cursor: pointer;
}
</style>
