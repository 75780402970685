import { defineStore } from 'pinia'

export const useSearchProcessingRowStore = defineStore('searchProcessingRowStore', {
  state: () => ({
    codes: '' as string,
    fullItems: [] as any[]
  }),
  actions: {
    resetFullItems() {
      this.fullItems = []
    },
    addItem(item: any) {
      this.fullItems.push(item)
    },
    resetCodes() {
      this.codes = ''
    },
    addCode(code: string) {
      this.codes = code
    }
  }
})
